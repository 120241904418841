/*
  Footer
*/
.footer {
  width: 100%;
  background: $primary-color;
  margin-top: auto;
  padding: 1.6rem 0;

  p {
    text-align: center;
    color: $white;
    font-size: 1.2rem;
  }
}
