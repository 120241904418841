/*
  Parts
*/

// Button
.btn {
  display: block;
  border-radius: 10000rem;
  font-size: 1.6rem;
  padding: 0.8rem 2.4rem !important;
  border-width: 0.2rem;
  border-style: solid;
  max-width: 24rem;
  min-width: 20rem;
  cursor: pointer;
  transition: 0.2s ease all;
  text-align: center;
  margin: 0 0.8rem;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--accent {
    border-color: $accent-color;
    background: $accent-color;
    color: $white  !important;

    &:hover {
      background: transparent !important;
      color: $accent-color  !important;
    }
  }

  &--secondary {
    border-color: $secondary-color;
    background: $secondary-color;
    color: $white;

    &:hover {
      background: transparent;
      color: $secondary-color;
    }
  }

  &--cancel {
    background: transparent;
    border-color: #aaa;
    color: #aaa;

    &:hover {
      background: #aaa;
      color: $white;
    }
  }
}

// Box
.box {
  padding: 3.2rem 3.2rem 4.8rem;
  background: $white;
  border-radius: 0.8rem;
}

// Page Title
.page-title {
  font-size: 2.4rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2.4rem;
}

.tag {
  display: inline-block;
  background: skyblue;
  font-size: 1.2rem;
  line-height: 1;
  color: $white;
  padding: 0.6rem 1.2rem;
  margin: 0 0.4rem 0.4rem 0;
  border-radius: 10000rem;

  &--travel {
    background: #00BFB2;
  }

  &--fashion {
    background: #ffc95e;
  }

  &--entertainment {
    background: #F79563;
  }

  &--sports {
    background: #F25D5A;
  }

  &--political-economy {
    background: #006AD1;
  }

  &--money {
    background: #79C362;
  }

  &--it {
    background: #a453d9;
  }
}

// Message
.msg {
  // position: fixed;
  // left: 50%;
  // bottom: 4rem;
  // visibility: hidden;
  max-width: 32rem;
  width: 100%;
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
  border-radius: 0.4rem;
  padding: 1.2rem;
  z-index: 1;
  margin: 0 auto 1.2rem;

  &.active {
    visibility: visible;
    animation: fadein 0.5s, fadeout 0.5s 3.5s;
  }

  &--info {
    position: fixed;
    left: 50%;
    bottom: 4rem;
    visibility: hidden;
    transform: translate(-50%, 0);
    background: #39991f;
  }

  &--error {
    background: #e92f2f;
  }

  &--debug {
    background: $primary-color;
  }
}

@media screen and (max-width: $bp) {

  // Button
  .btn {
    font-size: 1.4rem !important;
  }

  // Box
  .box {
    padding: 2rem 2rem 3.2rem;
  }
}
