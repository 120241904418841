/*
  Pagination
*/
.pagination {
  margin-top: 4.8rem;

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    margin-right: 1.6rem;
    margin-bottom: 1.6rem;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      border: 0.2rem solid $accent-color;
      color: $accent-color;
      width: 4rem;
      height: 4rem;

      &:hover {
        background: $accent-color;
        color: $white;
      }
    }

    &--current {
      a {
        pointer-events: none;
        background: $accent-color;
        color: $white;
      }
    }
  }
}

@media screen and (max-width: $bp) {
  .pagination {
    margin-top: 3.2rem;

    &__item {
      margin-right: 1.2rem;
      margin-bottom: 1.2rem;
    }
  }
}
