/*
  Post
*/

// No Post
.nopost {
  margin-top: 4.8rem;
  text-align: center;

  &__text {
    font-size: 1.6rem;
    margin-bottom: 2.4rem;
  }

  &__btn {
    display: flex;
    justify-content: center;

    .btn {
      width: 100%;
    }
  }
}

// Archive
.archive {
  &__create {
    display: flex;
    justify-content: center;
    margin-bottom: 2.4rem;

    .btn {
      width: 100%;
    }
  }

  &__list {
    display: grid;
    gap: 1.6rem;
    grid-template-columns: repeat(3, 1fr);
  }

  &__footer {
    margin-top: 4.8rem;
    padding-top: 4.8rem;
    border-top: 0.1rem solid $secondary-color;
    text-align: center;
    display: flex;
    justify-content: center;

    .btn {
      width: 100%;
    }
  }
}

// Post Item
.post-item {
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  &__image {
    position: relative;
    margin-bottom: 1.6rem;
    width: 100%;
    height: 12rem;

    a {
      width: 100%;
      height: 100%;
    }

    img {
      height: 100%;
      object-fit: cover;

      &.post-item__noimage {
        border: 0.1rem solid #eee;
      }
    }
  }

  &__status {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    color: $white;
    padding: 0.4rem 0.8rem;
    font-size: 1.2rem;

    &--publish {
      background: $accent-color;
    }

    &--draft {
      background: #aaa;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__category {
    margin-bottom: 0.8rem;
  }

  &__title {
    margin-bottom: 2.4rem;

    a {
      font-size: 1.8rem;
      font-weight: 700;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: auto;

    .time {
      font-size: 1.2rem;
    }

    .author {
      display: flex;
      align-items: center;
      font-size: 1.4rem;

      &__icon {
        display: block;
        margin-top: 0.2rem;
      }

      &__name {
        margin-left: 0.6rem;
        text-decoration: underline;
      }
    }
  }
}

// Single Post
.post {
  .form__control {
    margin-bottom: 4.8rem;
  }

  &__header {
    margin-bottom: 2.4rem;
  }

  &__category {
    margin-bottom: 0.4rem !important;
  }

  &__title {
    font-size: 2.8rem;
    margin-bottom: 1.6rem;
  }

  &__date {
    text-align: right;
    font-size: 1.4rem;
    display: flex;
    justify-content: flex-end;

    span {
      margin-left: 0.8rem;
    }
  }

  &__author {
    text-align: right;
    font-size: 1.4rem;
    display: flex;
    justify-content: flex-end;

    a {
      margin-left: 0.8rem;
      color: #555;
      text-decoration: underline;
    }
  }

  &__image {
    width: 100%;
    height: 40rem;
    margin-bottom: 4.8rem;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__footer {
    margin-top: 4.8rem;
    padding-top: 4.8rem;
    border-top: 0.1rem solid $secondary-color;
    text-align: center;
    display: flex;
    justify-content: center;

    .btn {
      width: 100%;
    }
  }
}

@media screen and (max-width: $bp) {

  // Archive
  .archive {
    &__list {
      gap: 2.4rem;
      grid-template-columns: repeat(1, 1fr);
    }

    &__footer {
      margin-top: 3.2rem;
      padding-top: 3.2rem;
    }
  }

  // Post Item
  .post-item {
    &__image {
      height: 44vw;
    }
  }

  // Single Post
  .post {
    .form__control {
      margin-bottom: 3.2rem;
    }

    &__title {
      font-size: 2.6rem;
    }

    &__image {
      height: 44vw;
      margin-bottom: 2.4rem;
    }

    &__footer {
      margin-top: 3.2rem;
      padding-top: 3.2rem;
    }
  }
}
