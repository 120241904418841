/*
  Base
*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  min-width: $base_w;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: $bg-color;
}

img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

a {
  display: block;
  text-decoration: none;
  transition: 0.2s ease all;
  color: #333;
}

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

select::-ms-expand {
  display: none;
}

textarea {
  resize: vertical;
}

.pc {
  display: block;
}

.sp {
  display: none;
}

.nowrap {
  white-space: nowrap;
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 4rem; opacity: 1;}
}

@keyframes fadeout {
  from {bottom: 4rem; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}


@media (max-width: $bp) {
  body {
    min-width: auto;
  }

  .pc {
    display: none;
  }

  .sp {
    display: block;
  }
}
