/*
  Form
*/
.form {
  &__control {
    margin-bottom: 3.2rem;
  }

  &__label {
    display: block;
    margin-bottom: 1.2rem;
    font-size: 1.8rem;
    .required {
      color: red;
      font-size: 1rem;
      margin-left: 0.4rem;
      vertical-align: 50%
    }
  }

  &__content {
    &--category {
      display: flex;
      flex-wrap: wrap;
    }

    input,
    textarea,
    select {
      width: 100%;
      font-size: 1.6rem;
      border-radius: 0.4rem;
      padding: 1.2rem;
      background: $white;
      border: 0.1rem solid #ccc;
    }

    input[type="file"] {
      border: none;
      padding: 0;
      width: auto;
    }

    input[type="radio"] {
      display: none;

      &:checked {
        +label {
          &:before {
            background: $accent-color;
          }

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  &__radio {
    margin: 0 2.4rem 0.8rem 0;

    label {
      position: relative;
      padding-left: 2.4rem;
      font-size: 1.6rem;
      cursor: pointer;

      &:before,
      &:after {
        content: "";
        position: absolute;
        display: block;
      }

      &:before {
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 2rem;
        height: 2rem;
        border-radius: 0.4rem;
        border: 0.2rem solid $accent-color;
      }

      &:after {
        top: 0.3rem;
        left: 0.7rem;
        width: 0.8rem;
        height: 1.4rem;
        background: transparent;
        border-bottom: 0.3rem solid $white;
        border-right: 0.3rem solid $white;
        transform: rotate(45deg);
        opacity: 0;
      }
    }
  }

  &__error {
    margin-top: 0.4rem;
    color: red;
    font-size: 1.4rem;
  }

  &__btn {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  }

  &__preview {
    position: relative;
    height: 40rem;

    .delete-image {
      position: absolute;
      text-indent: -10000rem;
      top: 2rem;
      right: 2rem;
      display: block;
      border-radius: 10000rem;
      background: rgba(0, 0, 0, 0.8);
      width: 4rem;
      height: 4rem;
      cursor: pointer;

      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2rem;
        height: 0.2rem;
        background: $white;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    .preview {
      height: 100%;
      object-fit: cover;
      display: none;

      &.active {
        display: block;
      }
    }
  }

  &__image {
    label {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40rem;
      border-radius: 0.4rem;
      background: $white;
      border: 0.1rem solid #ccc;
      cursor: pointer;

      img {
        height: 100%;
        object-fit: cover;
        border-radius: 0.4rem;
      }

      input {
        display: none;
      }

      a {
        background: $white;
        color: #ccc;
        font-size: 1.6rem;
        text-align: center;
      }
    }
  }

  &__select {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 48%;
      right: 1.2rem;
      transform: translate(0, -50%) rotate(-45deg);
      width: 0.8rem;
      height: 0.8rem;
      border-left: 0.2rem solid #ccc;
      border-bottom: 0.2rem solid #ccc;
    }
  }

  &__delete {
    margin-top: 4.8rem;
    text-align: center;

    button {
      color: red;
      font-size: 1.6rem;
      text-decoration: underline;
      display: inline-block;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: $bp) {
  .form {
    &__control {
      margin-bottom: 2.4rem;
    }

    &__label {
      display: block;
      margin-bottom: 1.2rem;
      font-size: 1.6rem;
    }

    &__btn {
      display: flex;
      flex-direction: column;

      .btn {
        margin: 0 auto 1.2rem;
        width: 100%;

        &:last-child {
          margin: 0 auto;
        }
      }
    }

    &__preview {
      height: 44vw;
    }

    &__image {
      label {
        height: 44vw;
      }
    }
    &__delete {
      margin-top: 3.2rem;

      button {
        font-size: 1.4rem;
      }
    }
  }
}
