/*
  Container
*/
.container {
  max-width: $base_w;
  width: 100%;
  margin: 0 auto;
  padding: 4.8rem 0;
}

@media screen and (max-width: $bp) {
  .container {
    margin: 0 auto;
    padding: 3.2rem 1.6rem;
  }
}
