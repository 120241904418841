/*
  Error
*/
.notFound {
  margin-top: 4.8rem;
  text-align: center;
  p {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 3.2rem;
  }
  img {
    max-width: 32rem;
    margin: 0 auto;
  }
}
